import React from "react";
import styled from "styled-components";
import ClientSlider from "../Elements/ClientSlider";
// Components
import PricingTable from "../Elements/PricingTable";

export default function Pricing() {
  return (
    <Wrapper id="pricing">
      <div className="whiteBg">
        <div className="container">
          <HeaderInfo>
            <h1 className="font40 extraBold">Poznaj naszą ofertę</h1>
            <p className="darkColor font20 medium" style={{ paddingTop: "10px" }}>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque tortor lorem, fringilla nec sollicitudin eget, vulputate quis libero.
            </p>
          </HeaderInfo>
        </div>
      </div>
        
      <div className="transparent" style={{ padding: "50px 0" }}>
        <div className="container">
          <ClientSlider />
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  width: 100%;
  padding: 50px 0;
`;
const HeaderInfo = styled.div`
  margin-bottom: 10px;
  @media (max-width: 860px) {
    text-align: center;
  }
`;





