import React from "react";
import styled from "styled-components";
import emailjs from "emailjs-com";
import FullButton from "../Buttons/FullButton";
// Assets
import { useRef } from "react";

export default function Contact() {

const form = useRef();
  
  const onSubmit = (e) => {
    e.preventDefault();
    emailjs.sendForm(
      'service_o3e83yh',
      'template_ucnais4',
      form.current,
      'xvJnsBoVLVyVLV0RG'
    ).then(
      result => console.log(result.text),
      error => console.log(error.text)
    ) 
    form.current.reset();
  };



  return (
    <Wrapper id="contact">
      <div className="sscSnowBg">
        <div className="container">
          <HeaderInfo>
            <h1 className="font40 extraBold">Skontaktuj się z nami</h1>
          </HeaderInfo>
          <div className="row" style={{ paddingBottom: "20px" }}>
            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
              
              <Form ref={form} onSubmit={onSubmit}>
                <label className="darkColor font20">Imię / Nazwa firmy:</label>
                <input type="text" id="from_name" name="from_name" className="font20 semiBold" />
                <label className="darkColor font20">Email:</label>
                <input type="text" id="reply_to" name="reply_to" className="font20 semiBold" />
                <textarea rows="4" placeholder="Treść wiadomości" cols="50" type="text" id="message" name="message" className="font20 semiBold" />
              </Form>
              <SumbitWrapper onClick={onSubmit} type="submit" className="flex">
                <FullButton type="submit" value="Wyślij wiadomość" className="pointer animate radius8" style={{ maxWidth: "220px" }} />
              </SumbitWrapper>
            </div> 
          </div>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  width: 100%;
`;
const HeaderInfo = styled.div`
  padding: 60px 0 0px 0;
  @media (max-width: 860px) {
    text-align: center;
  }
`;
const Form = styled.form`
  padding: 20px 0 30px 0;
  input,
  textarea {
    width: 100%;
    background-color: transparent;
    border: 0px;
    outline: none;
    box-shadow: none;
    border-bottom: 1px solid #707070;
    height: 30px;
    margin-bottom: 30px;
  }
  textarea {
    min-height: 100px;
  }
  @media (max-width: 860px) {
    padding: 30px 0;
  }
`;
const ButtonInput = styled.input`
  border: 1px solid #ba282e;
  background-color: #ba282e;
  width: 100%;
  font-size: 100%;
  font-family: 'Open Sans', sans-serif;
  font-weight: 500;
  padding: 20px;
  outline: none;
  color: #fff;
  :hover {
    background-color: #ba282e;
    border: 1px solid #ba282e;
    color: #fff;
  }
  @media (max-width: 991px) {
    margin: 0 auto;
  }
`;
const ContactImgBox = styled.div`
  max-width: 180px; 
  align-self: flex-end; 
  margin: 10px 30px 10px 0;
`;
const SumbitWrapper = styled.div`
  @media (max-width: 991px) {
    width: 100%;
    margin-bottom: 50px;
  }
`;









