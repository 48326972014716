import React from "react";
import styled from "styled-components";
import FullButton from "../Buttons/FullButton";
export default function SocialCredits() {
  return (
    <Wrapper id="socialCredits">
      <div className="whiteBg">
        <div className="container">
          <HeaderInfo>
            <h1 className="font40 extraBold">Dołącz do grupy hurtowej</h1>
            <p className="darkColor font20 medium" style={{ paddingTop: "10px" }}>
            Zapisz się do grupy MP Wear na Facebooku i zostań z nami w kontakcie!
            </p>
          </HeaderInfo>
          <div className="container" style={{ marginTop: "30px", marginBottom: "20px", maxWidth: "400px" }}>
           <a 
           href="https://www.facebook.com/groups/656914451890737" target="_blank" rel="noreferrer">
            <FullButton  title="Dołącz do grupy" >
            </FullButton>
            </a></div>
      </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  width: 100%;
  padding: 0px 0;
`;

const BtnCenterWrapper = styled.section`
  align-items: center;
  justify-content: center;
  max-width: 100%;
  @media (max-width: 960px) {
    margin: 0 auto;
  }
`;

const HeaderInfo = styled.div`
  margin-bottom: 0px;
  @media (max-width: 860px) {
    text-align: center;
  }
`;

const BtnWrapper = styled.div`
  max-width: 390px;
  min-width: 300px;
  @media (max-width: 960px) {
    margin: 0 auto;
  }
`;




