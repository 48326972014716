import React from "react";
import styled from "styled-components";
export default function TelephoneContact() {
  return (
    <Wrapper id="telephoneContact">
      <div className="whiteBg">
      <div className="container">
          <HeaderInfo>
            <h1 className="font40 extraBold">Skontaktuj się z nami i złóż zamówienie</h1>
            <BtnCenterWrapper>
            <h4 className="darkColor font25" style={{ padding: "15px 0" }}>Numer telefonu: 606 996 050</h4>
            </BtnCenterWrapper>
            <BtnCenterWrapper>
              <h4 className="darkColor font25" style={{ padding: "5px 0" }}>Adres email: info@mpwear.pl</h4>
            </BtnCenterWrapper>
          
         </HeaderInfo>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  width: 100%;
  padding: 30px 0;
`;

const BtnCenterWrapper = styled.section`
  display: flex;
  align-items: left;
  justify-content: left;
`;

const HeaderInfo = styled.div`
  margin-bottom: 10px;
  @media (max-width: 860px) {
    text-align: center;
  }
`;

const BtnWrapper = styled.div`
  font-size: 30px;
  max-width: 400px;
  @media (max-width: 960px) {
    margin: 0 auto;
  }
`;




