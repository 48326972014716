import React from "react";
import styled from "styled-components";

import ProfilePic from "../../assets/img/logo_mockup1.jpg"
import Scc from "../../assets/img/logo_mockup2.jpg"


export default function Services() {
  return (
    <Wrapper id="services">
      <div className="whiteBg" style={{ padding: "30px 0" }}>
        <div className="sscWhiteSmoke">
          <div className="container">
            <Advertising className="flexSpaceCenter">
              <AddLeft>
                <h2 className="font40 extraBold" >O MP</h2>
                <p className="darkColor font15" style={{textAlign: "justify"}}>
                MP to szwalnia działająca na terenie województwa łódzkiego już od ponad 20 lat. Firma posiada w swojej ofercie czapki, chustki oraz opaski dla dzieci i młodzieży. Doświadczenie w branży tekstylnej i pracy z klientem hurtowym pozwala na sprawną realizację powierzonych zamówień. Realizujemy wysyłki zarówno w Polsce jak i poza granicami kraju. Różnorodność modeli, rodzajów haftu i sitodruku pozwala na coroczne wprowadzenie rozbudowanych ofertowo kolekcji: wiosna-lato oraz jesień-zima. Przyjmujemy również zamówienia indywidualne.
                </p>
              </AddLeft>
              <AddRight>
                <AddRightInner>
                  <div className="flexNullCenter">
                    <AddImgWrapp1 className="flexCenter">
                      <img src={ProfilePic} alt="office" style={{maxWidth: "350px"}}/>
                    </AddImgWrapp1>
                  </div>
                </AddRightInner>
              </AddRight>
            </Advertising>
          </div>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  width: 100%;
`;
const Advertising = styled.div`
  margin: 30px 0;
  padding: 80px 0;
  position: relative;
  @media (max-width: 1160px) {
    padding: 100px 0 40px 0;
  }
  @media (max-width: 860px) {
    flex-direction: column;
    padding: 0 0 30px 0;
    margin: 80px 0 0px 0;
  }
`;
const AddLeft = styled.div`
  width: 50%;
  p {
    max-width: 475px;
  }
  h2 {
    margin: 10px;
  }
  @media (max-width: 860px) {
    width: 80%;
    order: 2;
    text-align: center;
    h2 {
      line-height: 3rem;
      margin: 15px 0;
    }
    p {
      margin: 0 auto;
    }
  }
`;
const AddRight = styled.div`
  width: 50%;
  position: absolute;
  top: 100px;
  right: 0;
  @media (max-width: 860px) {
    width: 80%;
    position: relative;
    order: 1;
    top: 10px;
  }
`;
const AddRightInner = styled.div`
  width: 100%;
`;
const AddImgWrapp1 = styled.div`
  width: 100%;
  margin: 5 16% 0px 6%;
  img {
    width: 100%;
    height: auto;
    border-radius: 1rem;
    box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
  }
`;
const AddImgWrapp2 = styled.div`
  width: 60%;
  margin: 0 5% -10px 5%;
  img {
    width: 130%;
    height: auto;
    border-radius: 1rem;
    box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
  }
`;