import React from "react";
import styled from "styled-components";
import themeMP from "../../assets/img/5.png"
export default function Photo() {
  return (
    <Wrapper id="photo">
        <div className="container">
        <img src={themeMP} style={{width:"120%" , maxHeight:"200px", maxWidth: "385px"}}/>
        </div>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  width: 100%;
  padding: 0px 0;
`;

const BtnCenterWrapper = styled.section`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px 0;
  max-width: 400px;
  @media (max-width: 960px) {
    margin: 0 auto;
  }
`;

const HeaderInfo = styled.div`
  margin-bottom: 0px;
  @media (max-width: 860px) {
    text-align: center;
  }
`;

const BtnWrapper = styled.div`
  font-size: 30px;
  max-width: 400px;
  @media (max-width: 960px) {
    margin: 0 auto;
  }
`;




