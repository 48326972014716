import React from "react";
// Sections
import TopNavbar from "../components/Nav/TopNavbar";
import Header from "../components/Sections/Header";
import Services from "../components/Sections/Services";
import Pricing from "../components/Sections/Pricing";
import Contact from "../components/Sections/Contact";
import Footer from "../components/Sections/Footer";
import SocialCredits from "../components/Sections/SocialCredits";
import TelephoneContact from "../components/Sections/TelephoneContact";
import Photo from "../components/Sections/Photo";

export default function Landing() {
  return (
    <>
      <TopNavbar />
      <Header />
      <Services />
      <TelephoneContact />
      <SocialCredits />
      <Footer />
    </>
  );
}


